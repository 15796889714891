<template>
    <Modal
        :onClose="onClose"
    >
        <ModalContent>
            <h3 class="modal-content__heading">
                Add Cors
            </h3>

            <div class="modal-content__input-container">
                <Input
                    label="Origin"
                    :value="origin"
                    :required="true"
                    :onChange="onChange"
                />
            </div>

            <div class="modal-content__buttons-container">
                <div class="modal-content__button-holder">
                    <Button
                        theme="transparent"
                        :fluid="true"
                        :onClick="onClose"
                    >
                        Cancel
                    </Button>
                </div>
                <div class="modal-content__button-holder">
                    <Button
                        :fluid="true"
                        :onClick="onAddClick"
                    >
                        Add
                    </Button>
                </div>
            </div>
        </ModalContent>
    </Modal>
</template>

<script src="./ModalAddCors.js"></script>
