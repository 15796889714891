import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { Preloader } from 'account-ui-components';
import Header from '@/components/Header/Header.vue';
import AlertList from '@/components/AlertList/AlertList.vue';

export default {
    name: 'App',

    components: {
        Preloader,
        Header,
        AlertList
    },

    setup() {
        const store = useStore();

        onMounted(() => {
            store.dispatch('init');
            store.dispatch('getUserData');
        });

        return {
            isUserAuthenticated: true
        };
    }
};
