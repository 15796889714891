import {
    reactive,
    ref,
    computed
} from 'vue';
import { useRouter } from 'vue-router';
import {
    Preloader,
    IconButton,
    Popover,
    ContextMenu,
    ContextMenuItem,
    Tabs,
    Tab,
    ConfirmModal
} from 'account-ui-components';
import { localeTimestampWithShortMonthName } from '@/helpers/timestamps';
import Icon from '@/components/Icon/Icon.vue';
import ModalLogoUpload from '@/components/modals/ModalLogoUpload/ModalLogoUpload.vue';
import ModalDeleteClient from '@/components/modals/ModalDeleteClient/ModalDeleteClient.vue';
import ModalDuplicateClient from '@/components/modals/ModalDuplicateClient/ModalDuplicateClient.vue';
import ClientSettingsContainer from '@/containers/ClientSettingsContainer/ClientSettingsContainer.vue';
import ClientScopesContainer from '@/containers/ClientScopesContainer/ClientScopesContainer.vue';
import ClientRedirectsContainer from '@/containers/ClientRedirectsContainer/ClientRedirectsContainer.vue';
import ClientSecretsContainer from '@/containers/ClientSecretsContainer/ClientSecretsContainer.vue';
import ClientClaimsContainer from '@/containers/ClientClaimsContainer/ClientClaimsContainer.vue';
import ClientPostLogoutRedirectUrisContainer from '@/containers/ClientPostLogoutRedirectUrisContainer/ClientPostLogoutRedirectUrisContainer.vue';
import ClientCorsContainer from '@/containers/ClientCorsContainer/ClientCorsContainer.vue';

export default {
    name: 'Client',

    props: {
        loading: {
            type: Object,
            required: true
        },
        client: {
            type: Object,
            default: null
        },
        onDuplicate: {
            type: Function,
            required: true
        },
        onLogoRemove: {
            type: Function,
            required: true
        }
    },

    components: {
        Preloader,
        IconButton,
        Popover,
        ContextMenu,
        ContextMenuItem,
        Tabs,
        Tab,
        Icon,
        ConfirmModal,
        ModalLogoUpload,
        ModalDeleteClient,
        ModalDuplicateClient,
        ClientSettingsContainer,
        ClientScopesContainer,
        ClientRedirectsContainer,
        ClientSecretsContainer,
        ClientClaimsContainer,
        ClientPostLogoutRedirectUrisContainer,
        ClientCorsContainer
    },

    setup(props) {
        const router = useRouter();
        const activeTab = ref('settings');
        const showClientActions = ref(false);
        const clientActions = ref(null);
        const modals = reactive({
            uploadLogo: false,
            duplicateClient: false,
            deleteClient: false
        });
        const grants = computed(() => (
            props.client.grants.map((grant) => grant.grantType)
        ));

        const getFormattedTimestamp = (utcTimestamp) => localeTimestampWithShortMonthName(utcTimestamp);

        const getFormattedClassification = (classification) => {
            let caplitalizedAndSplitClassifications = classification.replace(/([A-Z])/g, ' $1');
            caplitalizedAndSplitClassifications = caplitalizedAndSplitClassifications.charAt(0).toUpperCase() + caplitalizedAndSplitClassifications.slice(1);
            return caplitalizedAndSplitClassifications;
        };

        const toggleModal = (modal) => {
            modals[modal] = !modals[modal];
        };

        const onTabSelect = (id) => {
            activeTab.value = id;
        };

        const onBackClick = () => {
            router.push('/clients');
        };

        const toggleClientActionsMenu = () => {
            showClientActions.value = !showClientActions.value;
        };

        const onClientActionsClick = () => {
            toggleClientActionsMenu();
        };

        const onContextMenuItemClick = (id) => {
            toggleClientActionsMenu();
            toggleModal(id);
        };

        const onDuplicateConfirm = (newClientId) => {
            toggleModal('duplicateClient');

            props.onDuplicate(newClientId);
        };

        const onLogoRemoveConfirm = () => {
            toggleModal('removeLogo');

            props.onLogoRemove();
        };

        return {
            clientActions,
            showClientActions,
            activeTab,
            modals,
            grants,
            getFormattedTimestamp,
            getFormattedClassification,
            toggleModal,
            onTabSelect,
            onBackClick,
            toggleClientActionsMenu,
            onClientActionsClick,
            onContextMenuItemClick,
            onDuplicateConfirm,
            onLogoRemoveConfirm
        };
    }
};
