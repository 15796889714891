<template>
    <div class="client-cors">
        <div class="client-cors__title-container">
            <Title>
                CORS
            </Title>

            <Button
                theme="transparent"
                :onClick="onAddClick"
            >
                Add CORS
            </Button>
        </div>

        <DataTable
            :headers="headers"
            :items="client.cors"
        >
            <template v-slot:actions="{ item }">
                <div class="client-cors__table-actions">
                    <Icon
                        class="client-cors__remove-icon"
                        name="delete"
                        @click="onRemoveClick(item)"
                    />
                </div>
            </template>
        </DataTable>

        <ModalAddCors
            v-if="modals.addCors"
            :onClose="() => toggleModal('addCors')"
        />

        <ConfirmModal
            v-if="modals.removeCors"
            :text="`Please confirm that you want to remove ${corsToRemove.origin}`"
            :onYes="onRemoveConfirm"
            :onClose="() => toggleModal('removeCors')"
        />
    </div>
</template>

<script src="./ClientCors.js"></script>

<style
    src="./ClientCors.scss"
    lang="scss"
    scoped
></style>
