import {
    ref,
    computed
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
    Modal,
    Input,
    Button
} from 'account-ui-components';
import ModalContent from '../ModalContent/ModalContent.vue';

export default {
    name: 'ModalAddCors',

    components: {
        Modal,
        Input,
        Button,
        ModalContent
    },

    props: {
        onClose: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        const store = useStore();
        const route = useRoute();
        const origin = ref('');
        const clientId = computed(() => route.params.id);

        const onChange = ({ target }) => {
            origin.value = target.value;
        };

        const onAddClick = async () => {
            await store.dispatch('addClientCors', {
                clientId: clientId.value,
                origin: origin.value
            });

            props.onClose();
        };

        return {
            origin,
            onChange,
            onAddClick
        };
    }
};
