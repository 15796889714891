<template>
    <div class="app">
        <div
            v-if="!isUserAuthenticated"
            class="app__preloader-container"
        >
            <Preloader />
        </div>

        <template v-else>
            <Header />

            <div class="app__container">
                <div class="app__content-container">
                    <router-view />
                </div>
            </div>
        </template>
    </div>
</template>

<script src="./App.js"></script>

<style
    src="./App.scss"
    lang="scss"
    scoped
></style>
