<template>
    <div class="client">
        <template v-if="loading.page">
            <div class="client__preloader-container">
                <Preloader />
            </div>
        </template>

        <template v-else>
            <div class="client__top-container">
                <div class="client__action-button-container">
                    <IconButton
                        title="Back"
                        :onClick="onBackClick"
                    >
                        <Icon name="arrow_back" />
                    </IconButton>
                </div>

                <div class="client__main-information-container">
                    <div class="client__name-container">
                        <div
                            v-if="client.logoUri"
                            class="client__logo-container"
                        >
                            <img
                                class="client__logo"
                                :src="client.logoUri"
                                alt="Client Logo"
                            >
                        </div>
                        <div class="client__name">
                            {{ client.clientId }}
                        </div>
                    </div>
                    <div v-if="client.classification" class="client__classification">
                        ({{ getFormattedClassification(client.classification) }})
                    </div>
                </div>

                <div class="client__action-button-container">
                    <IconButton
                        ref="clientActions"
                        title="Client actions"
                        :onClick="onClientActionsClick"
                    >
                        <Icon name="more_vert" />
                    </IconButton>
                </div>
            </div>

            <div class="client__grants">
                {{ grants.join(', ') }}
            </div>

            <div class="client__date">
                Created: {{ getFormattedTimestamp(client.created) }}
                <div class="client__date-type-updated">
                    Updated: {{ getFormattedTimestamp(client.updated) }}
                </div>
            </div>

            <Tabs
                :activeTab="activeTab"
                :onChange="onTabSelect"
            >
                <Tab
                    id="settings"
                    title="Settings"
                />
                <Tab
                    id="scopes"
                    title="Scopes"
                />
                <Tab
                    id="redirects"
                    title="Redirects"
                />
                <Tab
                    id="secrets"
                    title="Secrets"
                />
                <Tab
                    id="claims"
                    title="Claims"
                />
                <Tab
                    id="postLogoutRedirectUris"
                    title="PLR Uris"
                />
                <Tab
                    id="cors"
                    title="CORS"
                />
            </Tabs>

            <ClientSettingsContainer v-if="activeTab === 'settings'" />
            <ClientScopesContainer v-else-if="activeTab === 'scopes'" />
            <ClientRedirectsContainer v-else-if="activeTab === 'redirects'" />
            <ClientSecretsContainer v-else-if="activeTab === 'secrets'" />
            <ClientClaimsContainer v-else-if="activeTab === 'claims'" />
            <ClientPostLogoutRedirectUrisContainer v-else-if="activeTab === 'postLogoutRedirectUris'" />
            <ClientCorsContainer v-else-if="activeTab === 'cors'" />

            <Popover
                v-if="showClientActions"
                :anchorEl="clientActions.$el"
                :onClose="toggleClientActionsMenu"
            >
                <ContextMenu>
                    <ContextMenuItem
                        :onClick="() => onContextMenuItemClick('uploadLogo')"
                    >
                        Upload logo
                    </ContextMenuItem>
                    <ContextMenuItem
                        v-if="client.logoUri"
                        :onClick="() => onContextMenuItemClick('removeLogo')"
                    >
                        Remove logo
                    </ContextMenuItem>
                    <ContextMenuItem
                        :onClick="() => onContextMenuItemClick('duplicateClient')"
                    >
                        Duplicate client
                    </ContextMenuItem>
                    <ContextMenuItem
                        :onClick="() => onContextMenuItemClick('deleteClient')"
                    >
                        Delete client
                    </ContextMenuItem>
                </ContextMenu>
            </Popover>

            <ModalLogoUpload
                v-if="modals.uploadLogo"
                :onClose="() => toggleModal('uploadLogo')"
            />

            <ConfirmModal
                v-if="modals.removeLogo"
                :text="`Please confirm that you want to remove ${client.clientId} logo`"
                :onYes="onLogoRemoveConfirm"
                :onClose="() => toggleModal('removeLogo')"
            />

            <ModalDeleteClient
                v-if="modals.deleteClient"
                :client="client"
                :onClose="() => toggleModal('deleteClient')"
            />

            <ModalDuplicateClient
                v-if="modals.duplicateClient"
                :onDuplicate="onDuplicateConfirm"
                :onClose="() => toggleModal('duplicateClient')"
            />
        </template>
    </div>
</template>

<script src="./Client.js"></script>

<style
    src="./Client.scss"
    lang="scss"
    scoped
></style>
